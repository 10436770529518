import * as React from "react";
import {
  Wrapper,
  PdpWrapper,
  DescriptionWrapper,
ArtistDescription,
  Citations,
  PhotosWrapper,
  CardWrapper,
  ProfilWrapper,
  NameWrapper,
  CarrouselWrapper2,
  LinkWrapper,
  ButtonWrapper,
  TitleWrapper,
  ArtistInfos,
  ImageWrapper,
  MobileProtrait,
  MoreButton,
  More,
} from "./RickProl.module.css";
import Title from "./../../../../components/Title";
import Carousel from 'react-multi-carousel';
import RickProlPhoto1 from "../../../../res/Photos site/Rick Prol/affiche B-side Gallery - Rick Prol.png"
import RickProlPhoto2 from "../../../../res/Photos site/Rick Prol/Rick Prol 1.png"
import RickProlPhoto3 from "../../../../res/Photos site/Rick Prol/Rick Prol 2.png"
import RickProlPhoto4 from "../../../../res/Photos site/Rick Prol/Rick prol au centre -1984.jpg"
import RickProlPhoto5 from "../../../../res/Photos site/Rick Prol/Rick prol expo.jpg"
import Button from "./../../../../components/Button";
import Pdp from '../../../../res/prol/portrait.jpg'
import PastPresent1 from "../../../../res/prol/past-present-1.jpg"
import PastPresent2 from "../../../../res/prol/past-present-2.jpg"
import PastPresent3 from "../../../../res/prol/past-present-3.jpg"
import { Helmet } from "react-helmet";

const isBrowser = typeof window !== "undefined";
const infos ={
  path: "RickProl",
  name: "RickProl",
  description:"Rick Prol est né en 1956 à New York, où il vit et travaille aujourd’hui. Il entre dans la vie artistique à la toute fin dans années 1970, face au paysage urbain du Lower East Side pleine d’insécurité, de vide, avec une violence quotidienne. Ses oeuvres reflètent cette espace psychique, peuplé de figures et d’animaux tordus. Il est diplômé de Cooper Union en 1980. Des études récentes de son travail ont été présentées à l'Ohio State University, Columbus, OH et au Hudson Valley Center for Contemporary Art, Peekskill, NY. Il a présenté des expositions personnelles avec Hal Bromm, B-Side Gallery, Willoughby Sharp et Andrew Kreps à New York ; Barbara Farber à Amsterdam; et la galerie Leeahn en Corée du Sud ; et a été inclus dans des expositions collectives à Artists Space et PS1, entre autres. Le travail de Prol fait partie des collections du Museum of Modern Art, du Stedelijk Museum, du Museum of Contemporary Art Chicago, du Hirschhorn Museum and Sculpture Garden, du Aldrich Contemporary Art Museum, du Holocaust Museum et des Smithsonian Libraries.",
  pdp: Pdp,
  alt_pdp: "Photo de profil de RickProl.",
  photos: [
    { src: PastPresent1, name: "RickProl" },
    { src: PastPresent2, name: "RickProl" },
    { src: PastPresent3, name: "RickProl" },
  ],
  works: ["Works", "Works", "Works"],
  videos: ["Videos", "Videos", "Videos"],
  citations: []
};

const RickProl = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
      paritialVisibilityGutter: 0
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      paritialVisibilityGutter: 0
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      paritialVisibilityGutter: 0
    },
    mobile: {
      breakpoint: { max: 428, min: 0 },
      items: 1,
      paritialVisibilityGutter: 0
    },
  };
  return (
    <div className={Wrapper}>
      <Helmet>
        {
            isBrowser ? window.onscroll = function(e){
              var sel = document.querySelector('#f')
              var end = document.querySelector('#end').offsetTop - 450;
              if (window.scrollY > end) {
                  sel.style.position = 'absolute';
                  sel.style.top = end.toString()+ "px";
              } else {
                  sel.style.position = 'fixed';
                  sel.style.top = 0;
              }
            } : null
          }
      </Helmet>
      <div className={CardWrapper}>
        <div className={ProfilWrapper} id="f">
          <img className={PdpWrapper} src={infos.pdp} alt={infos.alt_pdp} />
        </div>
        <div className={ArtistDescription}>
          <h1 className={TitleWrapper}>RICK PROL <h2 style={{paddingLeft: "16px"}}>(Américain)</h2></h1>
          <h3 className={NameWrapper} >Né en 1956</h3>
          <img className={MobileProtrait} src={infos.pdp} alt={infos.alt_pdp} />
          <div className={DescriptionWrapper} dangerouslySetInnerHTML={{__html:infos.description}}></div>
          <div  className={Citations}>
            {infos.citations.map((citations) => (
              <div>
                <p style={{fontStyle: "italic", textAlign: "center" }}>{citations}</p>
                <br />
              </div>
            ))}
          </div>
          <br />
        <ul className={ArtistInfos}>
          <p style={{fontWeight: "bolder"}}>EDUCATION</p>
          <br />
          <p style={{fontWeight: "bold"}}>1971 - 1975</p>
          <p>- High School of Music and Art, New York</p>
          <br />
          <p style={{fontWeight: "bold"}}>1974 - 1975</p>
          <p>- Joffrey Ballet, Scholarship Program, New York</p>
          <br />
          <p style={{fontWeight: "bold"}}>1975 - 1976</p>
          <p>- New York Studio School, New York</p>
          <br />
          <p style={{fontWeight: "bold"}}>1976 - 1980</p>
          <p>- Cooper Union, B.F.A., New York</p>
          <br />
          <div className={More} id="more">
          <br />
          <p style={{fontWeight: "bolder"}}>SOLO EXHIBITIONS</p>
          <br />
          <p style={{fontWeight: "bold"}}>2021</p>
          <p>- Cracked Window, Leeahn Gallery,Daegu, South Korea, May 6th - June 26th</p>
          <p>- Cracked Window, Leeahn Gallery, Seoul, South Korea, March 11th - April 24th.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2015</p>
          <p>- Under the Stars, The Dorian Grey Gallery, New York, USA, May 7th - June 20th.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2003</p>
          <p>- Maya Stendhal Gallery, New York, NY, USA 1996</p>
          <p>- Andrew Kreps Gallery, New York, NY, USA 1993</p>
          <p>- Kaj Forsblom Gallery, Helsinki</p>
          <p>- Frank Bustamante Gallery, New York, USA</p>
          <br />
          <p style={{fontWeight: "bold"}}>2003</p>
          <p>- Maya Stendhal Gallery, New York, NY, USA</p>
          <br />
          <p style={{fontWeight: "bold"}}>1996</p>
          <p>- Andrew Kreps Gallery, New York, NY, USA</p>
          <br />
          <p style={{fontWeight: "bold"}}>1993</p>
          <p>- Kaj Forsblom Gallery, Helsinki </p>
          <p>- Frank Bustamante Gallery, New York, USA</p>
          <br />
          <p style={{fontWeight: "bold"}}>1992</p>
          <p>- Stendhal Gallery, New York, NY, USA</p>
          <br />
          <p style={{fontWeight: "bold"}}>1991</p>
          <p>- Willoughby Sharp Gallery, New York, NY, USA</p>
          <br />
          <p style={{fontWeight: "bold"}}>1987</p>
          <p>- Peter Miller Gallery, Chicago</p>
          <p>- Saint Marks Gallery, New York, NY, USA</p>
          <br />
          <p style={{fontWeight: "bold"}}>1986</p>
          <p>- B-Side Gallery, New York, NY, USA</p>
          <p>- Nada Gallery, New York, NY, USA</p>
          <br />
          <p style={{fontWeight: "bold"}}>1985</p>
          <p>- Barbara Farber Gallery, Amsterdam</p>
          <p>- Hal Bromm, Gallery, New York, NY, USA</p>
          <p>- B-Side Gallery, New York, NY, USA</p>
          <br />
          <p style={{fontWeight: "bold"}}>1984</p>
          <p>- Hal Bromm Gallery, New York, NY, USA</p>
          <p>- B-Side Gallery, New York, NY, USA</p>
          <p>- East 7th Street Gallery, New York, USA</p>
          <br />
          <p style={{fontWeight: "bold"}}>1983</p>
          <p>- Piezo Electric Gallery, New York, NY, USA</p>
          <p>- Red Bar, New York, NY, USA</p>
          <br />
          <br />
          <p style={{fontWeight: "bolder"}}>GROUP EXHIBITIONS</p>
          <br />
          <p style={{fontWeight: "bold"}}>2022</p>
          <p>- Accrochage saisonnier, Ghost Galerie, Paris, France, Janvier-Juin 2022</p>
          <br />
          <p style={{fontWeight: "bold"}}>2021</p>
          <p>- Ghost DNA - exposition d’ouverture - opening exhibition, Ghost galerie, Paris, France, July 7th - September 4th.</p>
          <p>- Nos Fantômes - exposition inaugurale, Ghost galerie, Paris, France. 10 Septembre - 20 Novembre, 2021</p>
          <p>- Metamorphosis, Hal Bromm Gallery, Lower Manhattan, New York, USA, June 23rd - August 19th.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2017</p>
          <p>- Exuberant 80s : An East Village Painters, Circlem Johannes Vogtm Upper East Side, New York, USA, April 2nd - April 30th.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2014</p>
          <p>- Witness -- The Cedar Tavern Phone Booth Show, Westbeth Gallery, Greenwich Village, New York, USA, April 12th - 27th.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2013</p>
          <p>- James Romberger & Rick Prol, The Dorian Grey Gallery, New Yotrk, USA, October 16th - November 23rd.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2007</p>
          <p>- Time’s Up, Benefit, Protest Space, New York, NY, USA</p>
          <p>- Apokolypsis, May - August curated by Young Curators of the Art Museum of Western Virginia, Virginia, USA.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2006</p>
          <p>- Postcards from the Edge, Dec., presented by Sikkema Jenkins & Co. New York, NY, US, December 1st - 3rd.</p>
          <p>- Native Spirit, March. - April, curated by ; David Gibson, Supreme Trading, Bklyn, NY, USA</p>
          <p>- The Family, curated by : Ronnie Cutrone, Jonathan Shorr Gallery, New York, NY, USA</p>
          <br />
          <p style={{fontWeight: "bold"}}>2005</p>
          <p>- Street Urchins curated by Ronnie Cutrone, Casola Gallery, June - August, Peekskill NY, NY, USA</p>
          <p>- Vintage East Village, curated by Rick Prol, Hal Bromm Gallery, January - March, New York, NY, USA</p>
          <p>- East Village USA, curated by Dan Cameron, New Museum of Contemporary Art, New York, NY, USA</p>
          <br />
          <p style={{fontWeight: "bold"}}>2004</p>
          <p>- East Village USA, curated by Rick Prol and Jan Lynn Sokota, B-Side Gallery, New York, NY, USA</p>
          <br />
          <p style={{fontWeight: "bold"}}>2003</p>
          <p>- The Proposition Gallery, Arteries/ The Living Museum, curated by James Romberger and Marguerite Van Cook</p>
          <br />
          <p style={{fontWeight: "bold"}}>2002 - 2001</p>
          <p>- Postcards From the Edge, Sara Meltzer Gallery, New York, NY, USA</p>
          <br />
          <p style={{fontWeight: "bold"}}>2000</p>
          <p>- Art as Not’, Gregg Smith Collection, St. Louis, MO, USA</p>
          <p>- Hiding in Plain Sight, 450 Broadway Gallery, New York, NY, USA 1999</p>
          <p>- NY2K Curated by Rich Colicchio, Tracy Zungola and Carlo McCormick at El Bohio Cultural Center, New York, NY, USA</p>
          <p>- Heyday, Art from the East Village - The 1980’s, Life Cafe, New York, NY, USA</p>
          <p>- Valentine 99, Curated by Annie Heron & Larry Walczak, Eyewash Gallery, Brooklyn, USA</p>
          <p>- Littles, Jeffrey Coploff Fine Art Ltd., New York, NY, USA</p>
          <p>- Cadaver Dog, Curated by Rick Prol, Life Cafe, New York, NY, USA</p>
          <p>- Souvenirs/ Document : 20 Years, PS 122, New York, NY, USA</p>
          <p>- Déjà Jadis, 20TH Anniversary</p>
          <br />
          <p style={{fontWeight: "bold"}}>1997</p>
          <p>- Current Undercurrent : Working in Brooklyn, Brooklyn Museum of Art, Brooklyn, NY, USA</p>
          <br />
          <p style={{fontWeight: "bold"}}>1996</p>
          <p>- “Riptron” Curated by Rick Prol & Todd Hignite, B-Side Gallery, New York, NY, USA</p>
          <p>- “Waterline” Black and Herroa Gallery, New York, NY, USA</p>
          <p>- ‘Last Roundup” Livestock Gallery, New York, NY, USA</p>
          <br />
          <p style={{fontWeight: "bold"}}>1995</p>
          <p>- “Others Rooms” Work by Artists from four Brooklyn, Spaces, Ronald Feldman Fine-Arts, New York, NY, USA</p>
          <p>- 1st Holiday Group Show, Mary Anthony’s Gallery, New York, NY, USA</p>
          <p>- “Page 17” Curated by Gary Peterson, 450 Broadway Gallery, New York, NY, USA</p>
          <p>- “Doggie Style” Curated by Rick Prol, B-Side Gallery, New York, NY, USA</p>
          <br />
          <p style={{fontWeight: "bold"}}>1993</p>
          <p>- “Summer Group Show” Frank Bustamante Gallery, New York, NY, USA</p>
          <p>- “Group Show” Curated by Annie Herron, Test Site Gallery, New York, NY, USA</p>
          <p>- “Boundaries” Curated by David Betz, New York, NY, USA</p>
          <p></p>
          <p>- “Variations in Abstraction” Curated by Tom Schreiber Frank Bustamante Gallery, New York, USA</p>
          <br />
          <p style={{fontWeight: "bold"}}>1992</p>
          <p>- “Group Show” Franck Bustamante Gallery, New York, NY, USA</p>
          <br />
          <p style={{fontWeight: "bold"}}>1991</p>
          <p id="end">- “Painting” Vacuum Gallery, New York, NY, USA</p>
          </div>
        </ul>
            <button className={MoreButton} id="moreBtn" onClick={() => {
                 var sel = document.querySelector('#more');
                 var selBtn = document.querySelector('#moreBtn');
                 console.log("in there");

                
                  if (sel.style.display === "none") {
                    sel.style.display = "inline";
                    selBtn.innerHTML = "Read more";
                  } else {
                    sel.style.display = "none";
                    selBtn.innerHTML = "Read less";
                }
                 
              }}
               >Read more</button>
        </div>
      </div>
      
      <div className={PhotosWrapper}>
       <Title title={"Past or Present"} />
        <div className={CarrouselWrapper2}>
          {/* <Carrousel images={infos.photos} />
           */}
           <Carousel
            slidesToSlide={1}
            arrows={true}
            swipeable={true}
            draggable={true}
            responsive={responsive}
            infinite={false}
            customTransition="transform 500ms ease-in-out"
            transitionDuration={500}
            autoPlay={true}
            autoPlaySpeed={3000}
            showDots={false}
            partialVisbile={true}
            >
            {/* TODO: Faire les cartel ici */}
            {infos.photos.map((image) => (
                <img
                  className={ImageWrapper}
                  key={image.name}
                  alt={image.name}
                  src={image.src}
                />
            ))}
          </Carousel>
        </div>
      </div>
      <div className={ButtonWrapper}>
            <Button
              alt=""
              onClick={() => {
                if (typeof window !== "undefined") {
                  window.location.href = "/fr/contactez-nous";
                }
              }
            }
            >
              Contactez-nous
            </Button>
        </div>
    </div>
  );
};

export default RickProl;