// extracted by mini-css-extract-plugin
export var ArtistDescription = "RickProl-module--ArtistDescription--YApk1";
export var ArtistInfos = "RickProl-module--ArtistInfos--yZt4e";
export var ButtonWrapper = "RickProl-module--ButtonWrapper --RpmDH";
export var CardWrapper = "RickProl-module--CardWrapper--K2MG3";
export var CarrouselWrapper2 = "RickProl-module--CarrouselWrapper2--Zh3OZ";
export var Citations = "RickProl-module--Citations--0M7My";
export var DescriptionWrapper = "RickProl-module--DescriptionWrapper--ar1bf";
export var ImageWrapper = "RickProl-module--ImageWrapper--hjBSa";
export var LinkWrapper = "RickProl-module--LinkWrapper--ZglLO";
export var MobileProtrait = "RickProl-module--MobileProtrait--bAiS7";
export var More = "RickProl-module--More--pRY9S";
export var MoreButton = "RickProl-module--MoreButton--qXw4I";
export var NameWrapper = "RickProl-module--NameWrapper--aIPm6";
export var PdpWrapper = "RickProl-module--PdpWrapper--9MrKV";
export var PhotosWrapper = "RickProl-module--PhotosWrapper--35ZNZ";
export var ProfilWrapper = "RickProl-module--ProfilWrapper--qMF7a";
export var TitleWrapper = "RickProl-module--TitleWrapper--5c-XO";
export var Wrapper = "RickProl-module--Wrapper--HBu+u";